import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams} from "react-router-dom"
import {connect} from "react-redux";
import {QRCodeSVG} from 'qrcode.react' // 二维码生成工具

// 资源
import loadicon from "../resource/loading.png";
import e from "../resource/e.webp"

// 自定义组件
import FunctionContext from "../context";
import Comment1 from "./comment1";
import {ScoreStar} from "../../Utiles/utiles"
import Comment2 from "./comment2";
import {apis} from "../api";
import {setComment, setCurrent, setUserinfo} from "../../redux/action";
import {ReportPop} from "./popup";
import IndexItem2 from "./indexitem2";


function Item(props){
	
	// State
	const [loaded, setloaded] = useState(false)
	const [showfulldesc, setshowfulldesc] = useState(false)
	const [showqrcode, setshowqrcode] = useState(false)
	const [showreport, setshowreport] = useState(false)
	
	// 使用react router获取路由参数
	const paras = useParams()
	const appid = paras.id
	
	// context
  const functions = useContext(FunctionContext);
	
	// 路径更改
	useEffect(() => {
		intial()
	}, [appid]);
	
	useEffect(()=>{
		if(props.userinfo.logged){
			// 获取用户评论，去重并添加到评论列表的最前端
			apis('getusercomments', {app:appid}).then(
				(res) => {
					// 去重
					const quchong = dedupComments(props.comments, res)
					// 前端添加
					const new_comments = res.concat(quchong);
					props.setComment(new_comments)
				},
				(error) => {
					// console.log("get response failed!", error);
				}
			);
		}
	},[props.userinfo.logged, loaded])
	
	// 暂未开发
	const moreComments = () => {
		// 获取更多评论，去重并添加到评论列表最后端
		apis('getmorecomment', {app:appid}).then(
			(res) => {
				// console.log("get commentlist2 response:", res);
				// 去重
				const quchong = dedupComments(props.comments, res)
				// 前端添加
				const new_comments = res.concat(quchong);
				props.setComment(new_comments)
			},
			(error) => {
				// console.log("get response failed!", error);
			}
		);
	}
	
	const intial = () => {
		// 应用详情
		apis('getapp', {app:appid}).then(
			(res) => {
				// console.log("get app response:", res);
				props.setCurrent(res)
				// 评论
				props.setComment(res.comments)
				setloaded(true)
			}).catch(
			(error) => {
				console.log("getapp request failed!", error);
			}
		);
	}
	
	// 评论去重
	const dedupComments = (arr1, arr2) => {
		const arr2Set = new Set(arr2.map(item => JSON.stringify(item)));
		const diffArray = arr1.filter(item => !arr2Set.has(JSON.stringify(item)));
		return diffArray;
	}
	
	// 处理阅读更多内容
	const handleReadMore = () => {
		setshowfulldesc(!showfulldesc)
	}
	
	// 跳转到下载
	const handleDownload = (url) => {
		apis('download', {app:appid}).then(
			(res) => {
				window.open(res.link)
			}).catch(
			(error) => {
				console.log("download request failed!", error);
			}
		)
	}
	
	// 添加到收藏
	const handleFavorite = (status) => {
		if(status){
			apis('postfavorite', {app:appid}).then(
				(res) => {
					props.setCurrent({...props.current, favorite:true})
				}).catch((error) => {}
			)
		} else {
			apis('deletefavorite', {app:appid}).then(
				(res) => {
					props.setCurrent({...props.current, favorite:false})
				}).catch((error) => {}
			)
		}
	}
	
	const handleShare = () => {
		// 复制路径到剪贴板
    let dummy = document.createElement('input'),
      text = window.location.hostname + '/app/' + props.current.id

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
		
    // 左下角提示
    functions.setuptip('已复制路径到剪贴板')
	}
	
	if(!loaded){
    return(
      <div style={{width:'100%', textAlign:"center"}}><img src={loadicon} className='rotateImg indexloading' style={{height:'32px', width:'32px', margin:'10px auto'}} alt=''/></div>
    )
  }
	
	return (
			<div className='itemdiv'>
				<div className='item'>
					{/*基本信息*/}
					<div className='iteminfo'>
						<div className='iteminfodetail'>
							{/*名称*/}
							<h2 className='iteminfoname'>{props.current.name}</h2>
							{/*开发商*/}
							<Link to={"/provider/" + props.current.providerid.id} style={{textDecoration: "none"}}>
								<div className='iteminfocreator'>{props.current.providerid.name}</div>
							</Link>
							{/*tip*/}
							<div className='iteminfotip'>{props.current.tip}</div>
							{/*评分、下载量、分级*/}
							<div className='itemdetail2'>
								<div className='itemdetail3'>
									<div className='itemdetail4'>
										<div>{props.current.score}</div>
										<i className='material-icons' style={{fontSize:'1rem', marginLeft:'6px'}}>star</i>
                    {/*<div style={{backgroundImage: `url(${star9})`, height:'12px', width:'12px', , backgroundSize:"cover"}}/>*/}
									</div>
									<div className='itemdetail5'>{props.current.score_num}</div>
								</div>
								<div className='divide'/>
								<div className='itemdetail3'>
									<div>{props.current.download}</div>
									<div className='itemdetail5'>次下载</div>
								</div>
								<div className='divide'/>
								<div className='itemdetail3'>
									<img src={e} className='itemdetail6' alt='?'/>
									{/*<div className='itemdetail5'>适合所有人</div>*/}
									<div className='itemdetail5'>{props.current.level}</div>
								</div>
							</div>
							
							{/*下载、二维码、收藏、分享*/}
							<div className='iteminfoops'>
								<div className='iteminfoopsbtn' onClick={()=>{handleDownload(props.current.ossurl)}}>下载</div>
								<div className='iteminfoopsbtn' onClick={()=>{setshowqrcode(!showqrcode)}}>下载到手机</div>
								{props.current.favorite ?
									<div className='iteminfoopsshare' onClick={()=>{handleFavorite(false)}}>
										<i className='material-icons' style={{fontSize:'1.6rem', marginRight:'12px'}}>bookmark_added</i>
										已收藏
									</div>
									:
									<div className='iteminfoopslike' onClick={()=>{handleFavorite(true)}}>
										<i className='material-icons' style={{fontSize:'1.6rem', marginRight:'12px'}}>bookmark_add</i>
										收藏
									</div>
								}
								{showqrcode && <QRCodeSVG className='qrcode' size={100} value={window.location.href} />}
								
								<div className='iteminfoopsshare' onClick={handleShare}>
									<i className='material-icons' style={{fontSize:'1.6rem', marginRight:'12px'}}>share</i>
									分享
								</div>
								
							</div>
							
						</div>
						{/*logo*/}
						<img src={props.current.logooss} className='itemicon' alt='icon'/>
					</div>
					
					<div className='itemmore'>
						{/*图片、评分、评论、描述等*/}
						<div className='itemmoreleft'>
							
							{/*图片*/}
							<div className='itemscreenshots'>
								{props.current.images ?
									props.current.images.map((value, index) => (<img src={value.oss} className='itemscreenshot' key={index} alt='screenshot'/>))
									:
									<img alt='不可用'/>
								}
							</div>

							{/*更新信息*/}
							{props.current.updateinfo &&
								<div className='itemfeaturediv'>
									<div className='itemfeaturetitle'>新功能</div>
									<div className='itemfeaturetext'>{props.current.updateinfo}</div>
								</div>
							}
							
							{/*分类*/}
							<Link to={"/topic/" + props.current.category} style={{textDecoration: "none"}}>
								<div className='iteminfocategory'>{props.current.category}</div>
							</Link>
							
							{/*描述*/}
							<div className='itemdes'>
								<div className='itemfeaturetitle'>关于此应用</div>
								<div className='itemdescontent'>
									<div className={showfulldesc ? 'itemdescontentfulltext' : 'itemdescontenttext'}>{props.current.description}</div>
									<div className={showfulldesc ? 'itemdescontentgradienthidden' : 'itemdescontentgradient'}/>
								</div>
								<div className='itemdesmore' onClick={handleReadMore}>阅读更多内容</div>
							</div>
							
							{/*评分和评论*/}
							<div className='itemfeaturediv'>
								<div className='itemfeaturetitle'>评分及评论</div>
								{/*评分*/}
								<div className='itemscorediv'>
									<div className='itemcommentdiv'>
										<div className='itemcommentdivscore'>{props.current.score}</div>
										<ScoreStar num={props.current.score*20} size='18px'/>
										<div className='itemcommentdivhuman'>{props.current.score_num}</div>
									</div>
									<div className='itemcommentcount'>
										<div className='itemcommentcountitem' style={{position:"relative", top:0, bottom:0, left:0, right:0, width: '100%', backgroundColor: '#e8eaed'}}>
											<span className='itemcommentcountindex'>5</span>
											<div style={{position:"absolute", top:0, bottom:0, left:0, right:0, width: props.current.review_5.toString()+'%', backgroundColor: '#57bb8a', borderRadius:'12px', zIndex:100}}/>
										</div>
										<div className='itemcommentcountitem' style={{position:"relative", top:0, bottom:0, left:0, right:0, width: '100%', backgroundColor: '#e8eaed'}}>
											<span className='itemcommentcountindex'>4</span>
											<div style={{position:"absolute", top:0, bottom:0, left:0, right:0, width: props.current.review_4.toString()+'%', backgroundColor: '#9ace6a', borderRadius:'12px', zIndex:100}}/>
										</div>
										<div className='itemcommentcountitem' style={{position:"relative", top:0, bottom:0, left:0, right:0, width: '100%', backgroundColor: '#e8eaed'}}>
											<span className='itemcommentcountindex'>3</span>
											<div style={{position:"absolute", top:0, bottom:0, left:0, right:0, width: props.current.review_3.toString()+'%', backgroundColor: '#ffcf02', borderRadius:'12px', zIndex:100}}/>
										</div>
										<div className='itemcommentcountitem' style={{position:"relative", top:0, bottom:0, left:0, right:0, width: '100%', backgroundColor: '#e8eaed'}}>
											<span className='itemcommentcountindex'>2</span>
											<div style={{position:"absolute", top:0, bottom:0, left:0, right:0, width: props.current.review_2.toString()+'%', backgroundColor: '#ff9f02', borderRadius:'12px', zIndex:100}}/>
										</div>
										<div className='itemcommentcountitem' style={{position:"relative", top:0, bottom:0, left:0, right:0, width: '100%', backgroundColor: '#e8eaed'}}>
											<span className='itemcommentcountindex'>1</span>
											<div style={{position:"absolute", top:0, bottom:0, left:0, right:0, width: props.current.review_1.toString()+'%', backgroundColor: '#ff6f31', borderRadius:'12px', zIndex:100}}/>
										</div>
									</div>
								</div>
								
								{/*评论*/}
								{props.userinfo.logged ?
									<Comment1 color={props.userinfo.color} name={props.userinfo.username} id={appid} />
									:
									<div className='itemlogintip' onClick={()=>{functions.activeLogin()}}>请登陆后发表评论</div>
								}
								
								{/*评论*/}
								{
									props.comments.map((value, index) => (
										<Comment2 value={value} delete={true} key={index}/>
									))
								}
							</div>
							

							<div style={{display:"flex", alignItems:"center", color: '#202124', fontWeight: 500, fontSize: '16px', lineHeight: 1.4, margin: '20px 0', cursor:"pointer"}} onClick={()=>{setshowreport(true)}}>
								<i className='material-icons' style={{fontSize:'1.5rem', marginRight:'12px'}}>flag</i>
								举报不当内容
						  </div>
						</div>
						

						{/*开发者信息、相似应用、自家应用*/}
						<div className='itemmoreright'>
							<div className='itemfeaturetitle' style={{margin:'18px auto 18px 12px'}}>应用支持</div>
							<div className='itemsupportdiv'>
								{props.current.website &&
									<div className='itemsupportinfo'>
										<i className='material-icons itemsupporticon'>public</i>
										<div>
											<div className='itemsupporttitle'>网站</div>
											<div className='itemsupportdetail'>{props.current.website}</div>
										</div>
									</div>
								}

								{props.current.email &&
									<div className='itemsupportinfo'>
										<i className='material-icons itemsupporticon'>mail</i>
										<div>
											<div className='itemsupporttitle'>支持团队邮箱</div>
											<div className='itemsupportdetail'>{props.current.email}</div>
										</div>
									</div>
								}
								{props.current.address &&
									<div className='itemsupportinfo'>
										<i className='material-icons itemsupporticon'>location_on</i>
										<div>
											<div className='itemsupporttitle'>地址</div>
											<div className='itemsupportdetail'>{props.current.address}</div>
										</div>
									</div>
								}

								{props.current.private &&
									<div className='itemsupportinfo'>
										<i className='material-icons itemsupporticon'>gpp_maybe</i>
										<div>
											<div className='itemsupporttitle'>隐私权政策</div>
											<div className='itemsupportdetail'>{props.current.private}</div>
										</div>
									</div>
								}
							</div>
							
							{/*相似*/}
							{
								props.current.similars.length !== 0 &&
								<div>
									<div className='itemfeaturetitle' style={{margin:'18px auto 18px 12px'}}>类似应用</div>
									{props.current.similars.map((value, index) => (
										<IndexItem2 data={value} key={index}/>
									))}
								</div>
							}
							
							{/*自家产品*/}
							{
								props.current.selfapp.length !== 0 &&
								<div>
									<div className='itemfeaturetitle' style={{margin:'18px auto 18px 12px'}}>{props.current.providerid.name}的其他产品</div>
									{props.current.selfapp.map((value, index) => (
										<IndexItem2 data={value} key={index}/>
									))}
								</div>
							}
							
						</div>
					</div>
				</div>
				
				{/*应用举报*/}
				{showreport && <ReportPop close={()=>{setshowreport(false)}} data={props.current} tip={functions.setuptip}/>}
			</div>
	)
}

// export default Item;

const mapStateToProps = store => ({
	userinfo: store.app.userinfo,
	current: store.app.current,
	comments: store.app.comments,
});

const mapDispatchToProps = dispatch => ({
	setUserinfo: (userinfo) => dispatch(setUserinfo(userinfo)),
	setCurrent: (current) => dispatch(setCurrent(current)),
	setComment: (comments) => dispatch(setComment(comments))
});

export default connect(mapStateToProps, mapDispatchToProps)(Item);

